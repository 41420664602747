import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockpurchaseteamUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'spt_no', label: '分配单编号', sortable: true },
    { key: 'stock_id', label: 'stockId', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'company_name', label: '货主', sortable: true },
    { key: 'name', label: '名称', sortable: true },
    { key: 'code', label: '商品69码', sortable: true },
    { key: 'before_purchase_team_id', label: '分配前销售权', sortable: true },
    { key: 'before_qty', label: '库存数量', sortable: true },
    { key: 'before_purchase_cost', label: '分配前销售成本', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'create_time', label: '申请时间', sortable: true },
    { key: 'creator', label: '申请人', sortable: true },
    { key: 'check_time', label: '审核时间', sortable: true },
    { key: 'checker', label: '审核人', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('spt_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})
  const tabStatus = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey,tabStatus], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('stockpurchaseteam/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        status:tabStatus.value,
        spt_no:condition.value.sptNo,
        code:condition.value.code,
        name:condition.value.name,
        before_purchase_team_id:condition.value.beforePurchaseTeamId==null?null:condition.value.beforePurchaseTeamId.value,
        afterPurchaseTeamId:condition.value.afterPurchaseTeamId==null?null:condition.value.afterPurchaseTeamId.value,
        creator:condition.value.creatorId,
        checker:condition.value.checkerId,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存销售权分配列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,
    tabStatus,

    // Extra Filters
  }
}
