<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search_excel"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">导入</span>
              </b-button>
            </div>


          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal_excel"
        >
          <b-form

          >
            <b-row>
              <!--excel-->
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="show"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分配编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.sptNo"
                      name="receiveNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.code"
                      name="statementNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.name"
                      name="relationalNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分配前销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.beforePurchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分配后销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.afterPurchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="申请人"
                    type="input"
                    v-on:change="fromChildren($event,['creatorName','creatorId'])"
                    :params="['creatorName','creatorId']"
                    :value="condition.creatorName"
                    modalName="创建人"
                    placeholder="点击选择申请人"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="审批人"
                    type="input"
                    v-on:change="fromChildren($event,['checkerName','checkerId'])"
                    :params="['checkerName','checkerId']"
                    :value="condition.checkerName"
                    modalName="创建人"
                    placeholder="点击选择审批人"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>
      <xy-tab
          :tabs="getCodeOptions('purchase_team_status')"
          :statusValue="status"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      ></xy-tab>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">
                <template #cell(after_purchase_team_id)="data">
                  {{ getCodeLabel("purchase_team", data.item.after_purchase_team_id) }}
                </template>
              </b-table>
            </div>
          </b-card>
        </template>
        <!-- Column: Type -->
        <template #cell(spt_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.spt_no }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_team_status', data.item.status)}`"
          >
            {{ getCodeLabel("purchase_team_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(before_purchase_team_id)="data">
          {{ getCodeLabel("purchase_team", data.item.before_purchase_team_id) }}
        </template>

        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <template #cell(check_time)="data">
          {{ isEmpty(data.item.check_time)?'':toDate(data.item.check_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel("user", data.item.creator) }}
        </template>

        <template #cell(checker)="data">
          {{ getCodeLabel("user", data.item.checker) }}
        </template>

        <template #cell(before_qty)="data">
          库存：{{data.item.before_qty}} / 锁库：{{ data.item.ext.stockItemLockQty }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status===0" @click="changeStatus(data.item,1,'提交审核')">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <div v-if="data.item.status===1">
              <b-dropdown-item @click="changeStatus(data.item,2,'审核通过')">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">审核通过</span>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus(data.item,3,'驳回')">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>

            <div v-if="data.item.status===0||data.item.status===3">
              <b-dropdown-item @click="showPurchaseTeamStock(data.item)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="del(data.item)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
            </div>

            <b-dropdown-item v-if="data.item.status===2">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <!--销售权分配-->
        <b-modal
            id="modal-purchase-team"
            ok-only
            ok-title="取消"
            centered
            size="lg"
            title="销售权分配"
            ref="modalPurchaseTeam"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="仓库"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.warehouse_name"
                    readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="货主"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.company_name"
                    readonly=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品名称"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.name"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品69码"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.code"
                    readonly=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="当前销售权"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.purchaseTeam"
                    readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品库存"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.before_qty"
                    readonly=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品价格"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.before_purchase_cost"
                    readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="6"></b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="分配销售权"
                  label-for="cost_reference"
                  label-size="sm"
                  class="required"
              >
                <v-select
                    id="contract_type"
                    :options="getCodeOptions('purchase_team')"
                    :clearable="true"
                    v-model="purchaseTeamStock.newPurchaseTeam"
                    class="select-size-sm"
                    placeholder="请选择销售权"
                    @input="function() {
                    $forceUpdate()
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="分配数量"
                  label-for="cost_reference"
                  label-size="sm"
                  class="required"
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.purchaseTeamQty"
                    type="number"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="价格调整"
                  label-for="cost_reference"
                  label-size="sm"
                  class="required"
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.purchaseTeamCost"
                    type="number"
                    @change="function() {
                    purchaseTeamStock.afterCost = (purchaseTeamStock.before_purchase_cost-(-purchaseTeamStock.purchaseTeamCost)).toFixed(6)
                    $forceUpdate()
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="调整后价格"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="purchaseTeamStock.afterCost"
                    readonly=""
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="saveStockPurchaseTeam(0)"
            >
              保存
            </b-button>
          </b-col>
        </b-modal>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import stockpurchaseteamUseList from './stockpurchaseteamUseList'
import stockpurchaseteamStore from './stockpurchaseteamStore'
import Ripple from "vue-ripple-directive";
import XyInputButton from "@/views/components/xy/XyInputButton";
import XyTab from "@/views/components/xy/XyTab";
import {useToast} from "vue-toastification/composition";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";

export default {
  components: {
    AttachmentUpload,
    XyTab,
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {key: 'after_purchase_team_id', label: '分配后销售权'},
        {key: 'after_qty', label: '分配数量'},
        {key: 'after_cost_change', label: '调整价格'},
        {key: 'after_cost', label: '调整后价格'},
      ],
      advanced_search_modal: false,
      status:'1',
      purchaseTeamStock:{ext:{}},
      advanced_search_modal_excel: false,
      excelSqlId: '',
      show:true
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stockpurchaseteam/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockpurchaseteam')) store.registerModule('stockpurchaseteam', stockpurchaseteamStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockpurchaseteam')) store.unregisterModule('stockpurchaseteam')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        store.dispatch('stockpurchaseteam/changeStatus', {id: params.spt_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const del = function (params) {

      if (confirm("是否删除?")) {
        store.dispatch('stockpurchaseteam/del', {sptId: params.spt_id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const saveStockPurchaseTeam = function (params) {
      if (isEmpty(this.purchaseTeamStock.newPurchaseTeam)){
        toast.error("分配销售权必填!")
        return false
      }
      if (isEmpty(this.purchaseTeamStock.purchaseTeamQty)){
        toast.error("分配数量必填!")
        return false
      }
      if (this.purchaseTeamStock.purchaseTeamQty>(this.purchaseTeamStock.before_qty)){
        toast.error("分配数量不能大于"+(this.purchaseTeamStock.before_qty)+"!")
        return false
      }
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(this.purchaseTeamStock.purchaseTeamQty)) {
        toast.error('出库数量必须是正整数!')
        return false
      }
      store.dispatch('stockpurchaseteam/save',{
        spt_id:this.purchaseTeamStock.spt_id,
        status:params,
        after_purchase_team_id:this.purchaseTeamStock.newPurchaseTeam.value,
        after_qty:this.purchaseTeamStock.purchaseTeamQty,
        after_cost_change:this.purchaseTeamStock.purchaseTeamCost,
        after_cost:this.purchaseTeamStock.afterCost,
        before_cost:this.purchaseTeamStock.before_cost,
        stock_id:this.purchaseTeamStock.stock_id,
        before_purchase_cost:this.purchaseTeamStock.before_purchase_cost
      }).then(res => {
        if (res.data.code==0){
          toast.success(res.data.data)
          refetchData()
          this.$refs['modalPurchaseTeam'].hide()
        }else {
          toast.error(res.data.data)
          this.$refs['modalPurchaseTeam'].hide()
        }
      })
    }

    const showPurchaseTeamStock = function (params) {
      this.purchaseTeamStock = params
      this.purchaseTeamStock.purchaseTeam = getCodeLabel('purchase_team',params.before_purchase_team_id)
      this.purchaseTeamStock.newPurchaseTeam = getCode("purchase_team",params.ext.item[0].after_purchase_team_id)
      this.purchaseTeamStock.purchaseTeamQty = params.ext.item[0].after_qty
      this.purchaseTeamStock.purchaseTeamCost = params.ext.item[0].after_cost_change
      this.purchaseTeamStock.afterCost = params.ext.item[0].after_cost
      this.$refs['modalPurchaseTeam'].show()
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const searchByConditionExcel = function () {
      this.show = false;

      const params = {
        excelSqlId: this.excelSqlId,
      }
      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      store.dispatch('stockpurchaseteam/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              refetchData()
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      tabStatus,

      // UI
    } = stockpurchaseteamUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      tabStatus,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,

      advanced_search,
      changeStatus,
      del,
      fromChildren,
      saveStockPurchaseTeam,
      showPurchaseTeamStock,
      advanced_search_excel,
      onUploaded,
      searchByConditionExcel,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
